
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Paper,
    List,
    ListItem,
} from '@mui/material';
import { Lock, ChevronLeft } from '@mui/icons-material';

import { RootState } from '../../store/store';
import { URL_STATIC_CATEGORIES } from '../../utils/apiConstants';
import './Propuestas.css';
import { getTextNameFromPhoneLanguage, getTextSubNameFromPhoneLanguage, getZonasFromPhoneLanguage } from '../../utils/utils';

interface PropuestasProps {  
    style?: React.CSSProperties; 
}

export const Propuestas : React.FC<PropuestasProps> = props => {
    const {
        style,
    } = props;

    const categorias : Category[] = useSelector((state : RootState) => state.resources.categorias);
    const zonas : Zone[] = useSelector((state : RootState) => state.resources.zonas);
    //console.log(categorias);

    const [categorySelected, setCategorySelected] = useState<Category | null>(null);
    const [ciudades, setCiudades] = useState<Zone[]>([]);
    const [pageNumber, setPageNumber] = useState(0);

    const clickOnCategory = (cat : Category) => {
        setCategorySelected(cat);
        setPageNumber(1);
    };

	useEffect(() => {
		clickOnCategory(categorias[0])
	}, [])
	

    const listaCategorias = () => {
        return (
            <div style={{padding:20}}>
                <List sx={{ width: 360}}>
                    {categorias.map((categoria : Category) => (
                        <ListItem
                            onClick={()=> !categoria.comingSoon && clickOnCategory(categoria)}
                            key={categoria.id}
                            disableGutters 
                            style={{borderRadius:8, opacity: categoria.comingSoon ? 0.4 : 1}}
                        >
                            <div className='itemPropuesta' style={{backgroundColor: categoria.color, cursor: categoria.comingSoon ? 'default' : 'pointer'}}>
                                <img src={URL_STATIC_CATEGORIES + categoria.urlPic} alt='' width='60' height='60' style={{marginLeft:10}}/>
                                <div className='contentText'>
                                    <text className='titleProp'>
                                        {getTextNameFromPhoneLanguage(categoria)}
                                    </text>
                                    <text className='subtitleProp'>
                                        {getTextSubNameFromPhoneLanguage(categoria)}
                                    </text>
                                </div>
                                {
                                    categoria.comingSoon ? <Lock fontSize='small' style={{position: 'absolute', bottom: 14, right: 4}}/> : null
                                }
                            </div>
                            
                        </ListItem>
                    ))}
                </List>  
            </div>  
        );
    }

    const clickOnZona = (zona : Zone) => {
        setCiudades(zona.children!);
        setPageNumber(2);
    };

    const listaZonas = () => {
        return (
            <div>               
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', position: 'relative'}}>               
                    {
                        categorySelected !== null && categorySelected.urlLogo !== null &&
                        <>
							<img src={categorySelected.urlLogo} alt='' width='60' height='60' style={{marginTop: 16}} />
							<div className='contentCatText'>
								<text className='titleCatProp'>
									{getTextNameFromPhoneLanguage(categorySelected)}
								</text>
							</div>
						</>
                    }                
                </div>
                <div style={{padding: 20, paddingTop: 0}}>
                    <List sx={{ width: 360}}>
                        {zonas.map((zona : Zone) => (
                            <ListItem
                                onClick={()=> (zona.status && zona.status === 'publish') && clickOnZona(zona)}
                                key={zona.id}
                                disableGutters 
                                style={{borderRadius:8, opacity: (zona.status && zona.status === 'draft') ? 0.7 : 1, cursor: (zona.status && zona.status === 'draft') ? 'default' : 'pointer'}}
                            >
                                <img src={zona.imagen_url} alt='' width='360' height='80' style={{borderRadius:8}}/>
                                <text className='textPais'>{getZonasFromPhoneLanguage(zona)}</text>
                                {
                                    (zona.status && zona.status === 'draft') ? <Lock fontSize='small' style={{position: 'absolute', bottom: 14, right: 4}}/> : null
                                }
                                
                            </ListItem>
                        ))}
                    </List>  
                </div> 
            </div> 
        );
    };

    const clickOnCity = (ciudad : Zone) => {
        ciudad.wordpress_url && window.open(ciudad.wordpress_url, '_blank');
    };

    const listaCities = () => {
        return (
            <div>                
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', position: 'relative'}}>               
                    <ChevronLeft style={{fontSize: 50, position: 'absolute', left: 8, top: 30 }} onClick={()=> setPageNumber(1)}/>     
                    {
                        categorySelected !== null && categorySelected.urlLogo !== null &&
                        <img src={categorySelected.urlLogo} alt='' width='80' height='80' style={{marginTop: 16, }} />
                    }                
                </div> 
                <div style={{padding: 20, paddingTop: 0}}>
                    <List sx={{ width: 360}}>
                        {ciudades.map((zona : Zone) => (
                            <ListItem
                                onClick={()=> (zona.status && zona.status === 'publish') && clickOnCity(zona)}
                                key={zona.id}
                                disableGutters 
                                style={{borderRadius:8, opacity: (zona.status && zona.status === 'draft') ? 0.7 : 1, cursor: (zona.status && zona.status === 'draft') ? 'default' : 'pointer'}}
                            >
                                <img src={zona.imagen_url} alt='' width='360' height='80' style={{borderRadius:8}}/>
                                <text className='textPais'>{getZonasFromPhoneLanguage(zona)}</text>
                                {
                                    (zona.status && zona.status === 'draft') ? <Lock fontSize='small' style={{position: 'absolute', bottom: 14, right: 4}}/> : null
                                }
                                
                            </ListItem>
                        ))}
                    </List>  
                </div> 
            </div> 
        );
    };

    return ( 
        <Paper elevation={2} style={{...style, width: 400, marginBottom: 24, zIndex: 0}}>
            { 
                pageNumber === 0 ?
                    listaCategorias() 
                    :
                    pageNumber === 1 ?
                        listaZonas()
                        :
                        listaCities()
            }     
        </Paper>
    );
}  
